import { useParams } from 'react-router-dom';
import React from 'react';
import { Container } from 'react-bootstrap';
import stylePost from '../../Components/Tags/Post.module.css';
import isMobile from '../../Components/Helper/Mobile';
import Head from '../../Components/Tags/Head';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Paragrafo from '../../Components/Tags/Paragrafo';
import H2 from '../../Components/Tags/H2';
import RatingStars from '../../Components/Rating/RatingStars';
import Categorias from '../../Components/Categorias/Categorias';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import stylesButton from '../../Pages/_css/Button.module.css';
import ItemDuvida from '../../Components/Tags/ItemDuvida';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import AdsContent2 from '../../Components/AdsJoinAds/AdsContent2';

const QuantoRendeMilhoesPouso = () => {
  const params = useParams();
  const mobile = isMobile();
  const [produto, setProduto] = React.useState(null);
  const [valorMilhoes, setValorMilhoes] = React.useState(params.valor * 1000);

  React.useEffect(() => {
    // retornaResultados(params.valor, 12);
    window.scroll(0, 0);
    getNomeProduto();
  }, []);

  function getNomeProduto() {
    if (params.produto === 'poupanca') {
      setProduto('Poupança');
    } else if (params.produto === 'mercadopago') {
      setProduto('Mercado Pago');
    } else if (params.produto === 'nubank') {
      setProduto('Nubank');
    } else if (params.produto === 'picpay') {
      setProduto('PicPay');
    } else if (params.produto === 'pagbank') {
      setProduto('PagBank');
    } else if (params.produto === 'tesouro') {
      setProduto('Tesouro Selic');
    } else if (params.produto === 'next') {
      setProduto('Banco Next');
    } else if (params.produto === 'inter') {
      setProduto('Banco Inter');
    } else if (params.produto === '99pay') {
      setProduto('99Pay');
    } else if (params.produto === 'neon') {
      setProduto('Neon');
    } else if (params.produto === 'iti') {
      setProduto('Iti');
    } else if (params.produto === 'will') {
      setProduto('Will Bank');
    } else if (params.produto === 'bv') {
      setProduto('Banco BV');
    } else if (params.produto === 'c6') {
      setProduto('C6 Bank');
    } else if (params.produto === 'sofisa') {
      setProduto('Banco Sofisa');
    } else if (params.produto === 'renda-fixa') {
      setProduto('Renda Fixa');
    } else if (params.produto === 'cdb') {
      setProduto('CDB');
    } else if (params.produto === 'banco-digital') {
      setProduto('Banco Digital');
    } else {
      setProduto('Poupança');
    }
  }

  function getTitle() {
    let titulo = '';

    if (params.valor > 0) {
      titulo = 'Quanto Rende ' + params.valor + ' Milhões de Reais ';
    } else {
      titulo = 'Quanto Rende Seu Dinheiro ';
    }

    if (params.produto === 'poupanca') {
      titulo = titulo + 'na Poupança';
    } else if (params.produto === 'mercadopago') {
      titulo = titulo + 'no Mercado Pago';
    } else if (params.produto === 'nubank') {
      titulo = titulo + 'no Nubank';
    } else if (params.produto === 'picpay') {
      titulo = titulo + 'no PicPay';
    } else if (params.produto === 'pagbank') {
      titulo = titulo + 'no PagBank';
    } else if (params.produto === 'next') {
      titulo = titulo + 'no Banco Next';
    } else if (params.produto === 'inter') {
      titulo = titulo + 'no Banco Inter';
    } else if (params.produto === '99pay') {
      titulo = titulo + 'na 99Pay';
    } else if (params.produto === 'neon') {
      titulo = titulo + 'no Banco Neon';
    } else if (params.produto === 'iti') {
      titulo = titulo + 'no Iti';
    } else if (params.produto === 'will') {
      titulo = titulo + 'no Will Bank';
    } else if (params.produto === 'bv') {
      titulo = titulo + 'no Banco BV';
    } else if (params.produto === 'c6') {
      titulo = titulo + 'no C6 Bank';
    } else if (params.produto === 'sofisa') {
      titulo = titulo + 'no Banco Sofisa';
    } else if (params.produto === 'tesouro') {
      titulo = titulo + 'no Tesouro Selic';
    } else if (params.produto === 'renda-fixa') {
      titulo = titulo + 'em Renda Fixa';
    } else if (params.produto === 'cdb') {
      titulo = titulo + 'em CDB';
    } else if (params.produto === 'banco-digital') {
      titulo = titulo + 'em Banco Digital';
    } else {
      titulo = titulo + 'na Poupança';
    }

    return titulo;
  }

  function retornaShemaOrg() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/milhoes/' +
      params.produto;

    return (
      <script type="application/ld+json">
        {`{
            "@context":"https://schema.org",
            "@type":"WebPage",
            "@id": "${endereco}#webpage",
            "url":"${endereco}",
            "name":"${getTitle()}",
            "isPartOf":
              {
                "@id":"https://www.guardardinheiro.com.br/#website"
                },
            "description":"${getTitle()}",
            "inLanguage":"pt-BR"
          }`}
      </script>
    );
  }

  function retornaSchemaBreadcrumbList() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/milhoes/' +
      params.produto;

    return (
      <script type="application/ld+json">
        {`{
          "@context":"https://schema.org",
          "@type":"BreadcrumbList",
          "itemListElement":
            [
              {
                "@type":"ListItem",
                "position":1,
                "name":"Guardar Dinheiro",
                "item":"https://www.guardardinheiro.com.br"
              },
              {
                "@type":"ListItem",
                "position":2,
                "name":"${getTitle()}",
                "item":"${endereco}"
              }              
            ]
        }`}
      </script>
    );
  }

  return (
    <div className="App">
      <Head
        title={getTitle()}
        metaDescription={getTitle()}
        h1={getTitle()}
        canonical={
          'https://www.guardardinheiro.com.br/quanto-rende/' +
          params.valor +
          '/milhoes/' +
          params.produto
        }
        nivel="2"
        id_wp_post={2202}
      />

      <div>
        <Container>
          <BreadcumbH1 paginaAtual={getTitle()} texto={getTitle()} />

          <div className={stylePost.container}>
            <div className={stylePost.itemPost}>
              <AdsContent1 />
              <h1 className={stylesTipografia.h1}>{getTitle()}</h1>
              <Paragrafo
                texto={
                  'Simulador de Rendimento de ' +
                  params.valor +
                  ' Milhões de Reais em ' +
                  produto +
                  ' para Guardar Dinheiro com a Selic a 10,75% ao ano.'
                }
              />

              <Paragrafo
                texto={
                  'O Simulador ' +
                  produto +
                  ', faz uma simulação de forma simples de quanto seu dinheiro vai render no ' +
                  produto +
                  ' no período informado.'
                }
              />

              <AdsContent2 />
              <Paragrafo
                texto={
                  'Essa funcionalidade ajuda muitos investidores iniciantes onde é melhor investir seu dinheiro e também a entender qual será a expectativa de retorno no futuro.'
                }
              />

              <Paragrafo
                texto={
                  'O prazo do investimento de ' +
                  params.valor +
                  ' Milhões de Reais em ' +
                  produto +
                  ' é crucial. Quanto mais tempo você mantiver seu dinheiro investido, maior será o rendimento devido aos juros compostos e também pagará menos imposto.'
                }
              />

              <Paragrafo
                texto={
                  'Nosso simulador ' +
                  produto +
                  ', também apresenta uma simulação dos melhores investimentos de renda fixa atualmente, ou seja, será possível comparar o rendimento de ' +
                  params.valor +
                  ' Milhões de Reais em ' +
                  produto +
                  ' com outras instituições financeiras e analisar qual possui o melhor rendimento no momento.'
                }
              />

              <Paragrafo
                texto={
                  'Investir em renda fixa atualmente vem sendo uma das escolhas mais populares devido à sua segurança e previsibilidade. Agora que você já conhece como funciona nosso simulador, acesse aqui O Simulador de ' +
                  getTitle() +
                  '.'
                }
              />
              <a
                href={
                  '/quanto-rende/' +
                  valorMilhoes.toString() +
                  '/mil/' +
                  params.produto +
                  '/simulador'
                }
                className={stylesButton.a}
              >
                ACESSAR SIMULADOR
              </a>

              <br />
              <br />
              <br />
              <H2 texto="Dúvidas frequentes" />
              <ItemDuvida duvida="selic" />
              <ItemDuvida duvida="cdb" />
              <ItemDuvida duvida="lci" />
              <ItemDuvida duvida="lca" />

              <br />
            </div>
            {!mobile ? (
              <div className={stylePost.itemCategorias}>
                <Categorias />
              </div>
            ) : null}
          </div>
          <br />
        </Container>
      </div>

      {/* <hr></hr>
      <RatingStars
        texto={'O que achou da nossa página?'}
        pagina={'quanto-rende-rf'}
        title={getTitle()}
        metaDescription={getTitle()}
      /> */}
    </div>
  );
};

export default QuantoRendeMilhoesPouso;
