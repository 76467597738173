import React from 'react';
import AdsNobeta1 from '../AdsNobeta/AdsNobeta1';
import AdsHelper from './AdsHelper';

const AdsContent4 = () => {
  const [contentVisible, setContentVisible] = React.useState(true);
  React.useEffect(() => {
    var path = '/22523725657/guardardinheiro.com.br/Guardardinheiro_Content4';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
    ];
    var id = 'Content4';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250], [336, 280]])
        .build();

      googletag
        .defineSlot(path, size, id)
        .setTargeting('test', 'lazyload')
        .defineSizeMapping(mapping)
        .setCollapseEmptyDiv(true)
        .addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContentVisibility = () => {
      const contentElement = document.getElementById('Content4');
      if (contentElement) {
        setContentVisible(
          window.getComputedStyle(contentElement).display !== 'none',
        );
      }
    };

    checkContentVisibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContentVisibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        id="Content4"
        style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}
      ></div>
      {!contentVisible && (
        <div
        // style={{
        //   border: '1px solid #00bfff',
        // }}
        >
          {/* <AdsMGIDArtigo /> */}
          {/* <AdsNobeta1 /> */}
          {/* <AdsNobeta3 /> */}
        </div>
      )}
      <AdsHelper segundos={500} name="ViewTaxContent4" />
    </>
  );
};
export default AdsContent4;
